import React from 'react';
import AboutUttarana from './AboutUttarana';
import AboutMohamGroup from './MohamGroup';
import ManagementTeam from './ManagementTeam';

const AboutTabContent = ({ activeTab }) => {
    let ActiveComponent;
    switch (activeTab) {
        case 'About Uttarana':
            ActiveComponent = AboutUttarana;
            break;
        case 'About Moham Group':
            ActiveComponent = AboutMohamGroup;
            break;
        case 'Management Team':
            ActiveComponent = ManagementTeam;
            break;
        default:
            ActiveComponent = AboutUttarana;
    }

    return (
        <div>
            <ActiveComponent />
        </div>
    );
};

export default AboutTabContent;
