// import { React, Slider, Link } from "../../../common";
// import Mp4Video from "../video/landing.mp4";
// import WebmVideo from "../video/landing.webm";
// import { DonatePop } from "../../components";

// const SliderComponent = ({ children }) => {
//   var settings = {
//     dots: false,
//     infinite: true,
//     speed: 900,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 10000,
//   };

//   return <Slider {...settings}>{children} </Slider>
// }

// const Component = () => {
//   const [isOpen2, setIsOpen2] = React.useState(false);

//   const togglePopup2 = () => {
//     setIsOpen2(!isOpen2);
//   };

//   return (
//     <>
//       <div id="homeVideo">
//         <div className="videoContainer">
//           <ul className="videoBG">
//             <li className="videoBG"></li>
//           </ul>
//           <video className="video1-bg" autoPlay={true} loop muted style={{ width: '100%' }}>
//             <source src={Mp4Video} />
//             <source src={WebmVideo} />
//             Video not supported.
//           </video>
//         </div>
//         <div className="fallback_smallscreen"></div>

//         <div className="videoContent_blk">
//           <div className="widthfix">
//             <ul className="videoContent">
//               <li className="videoContent">
//                 <div className="widthfix_corousel">
//                   <SliderComponent>
//                     <div className="widthfix_corousel_content">
//                       <div className="vc_line1">Smart Lending:</div>
//                       <div className="vc_line2">AI & Blockchain-Powered Banking SaaS Solutions </div>
//                       <div className="vc_line3">Advanced automation for Loan Origination, Collections, Dealer Management, Operations, and more</div>
//                       <div className="vcButtonBlk">
//                         <Link to="/solutions"><button class="vcButton">View More</button></Link>
//                       </div>
//                     </div>

//                     <div className="widthfix_corousel_content">
//                       <div className="vc_line1">Streamlined Integrations:</div>
//                       <div className="vc_line2">US Mortgage AI & Blockchain Solutions </div>
//                       <div className="vc_line3">AI-enhanced Encompass and Calyx integrations to optimize processing time and workflow efficiency</div>
//                       <div className="vcButtonBlk">
//                         <Link to="/solutions"><button class="vcButton">View More</button></Link>
//                       </div>
//                     </div>

//                     <div className="widthfix_corousel_content">
//                       <div className="vc_line1">Intelligent Assurance:</div>
//                       <div className="vc_line2">Comprehensive IT solutions from start to finish</div>
//                       <div className="vc_line3">AI-driven underwriting, fraud detection, blockchain-based security, and smart analytics for better decision-making</div>
//                       <div className="vcButtonBlk">
//                       <Link to="/solutions"><button class="vcButton">View More</button></Link>
//                       </div>
//                     </div>

//                   </SliderComponent>
//                 </div>
//                 <DonatePop isOpen={isOpen2} onClose={togglePopup2} />
//               </li>
//               <li className="videoContent">
//                 <div className="joinCommunity">
//                   <div className="joinHeadBlk">
//                     <h1>Contact Us for Banking SaaS</h1>
//                     <h2>Reach us for cutting-edge software solutions, expert guidance &amp; seamless development</h2>
//                   </div>
//                   <div className="joinFormContainer">
//                     <form>
//                       <div className="userIcon"></div>
//                       <input
//                         type="text"
//                         id="name"
//                         placeholder="Name"
//                       />
//                       <ul className="joinFormContainerIIcol">
//                         <li className="joinFormContainerIIcol">
//                           <div className="phIcon"></div>
//                           <div className="getOtpBtnBlk">
//                             {/* <input type="button" className="getOtpBtn" value="Get OTP" /> */}
//                           </div>
//                           <input
//                             type="text"
//                             id="phone"
//                             placeholder="Phone"
//                             className="no-spinner"
//                           />
//                         </li>
//                         <li className="joinFormContainerIIcol">
//                           <div className="mailIcon"></div>
//                           <input
//                             type="text"
//                             id="mail"
//                             placeholder="Email"
//                             className="no-spinner"
//                           />
//                         </li>
//                       </ul>
//                       <div className="termsAgree">By clicking on Connect Now you agree to our <Link to="/terms">Terms of Use</Link></div>
//                       <div className='buttonLabel'>
//                         <input type="button" className="joinButton" value="Connect Now" />
//                       </div>
//                     </form>
//                   </div>
//                   <div className="socialMedia">
//                     <div className="socialMediaIcons">
//                       <Link to="#" target="_blank" rel="noopener noreferrer">
//                         <i className="instaIcon"></i>
//                       </Link>
//                       <Link to="#" target="_blank" rel="noopener noreferrer">
//                         <i className="twitterIcon"></i>
//                       </Link>
//                       <Link to="#" target="_blank" rel="noopener noreferrer">
//                         <i className="facebookIcon"></i>
//                       </Link>
//                       <Link to="#" target="_blank" rel="noopener noreferrer">
//                         <i className="youtubeIcon"></i>
//                       </Link>
//                       <Link to="#" target="_blank" rel="noopener noreferrer">
//                         <i className="whatsAppIcon"></i>
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div >
//     </>
//   );
// };

// export default Component;


import { React, Slider, Link } from "../../../common";
import Mp4Video from "../video/landing.mp4";
import WebmVideo from "../video/landing.webm";
import { DonatePop } from "../../components";

const SliderComponent = ({ children }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return <Slider {...settings}>{children} </Slider>;
};

const Component = () => {
  const [isOpen2, setIsOpen2] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: "",
    phone: "",
    email: "",
  });
  const [message, setMessage] = React.useState("");
const [isSuccess, setIsSuccess] = React.useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = () => {
    const { name, phone, email } = formData;
    if (name.trim() && phone.trim() && email.trim()) {
      setMessage("Your request has been received. We will contact you soon!");
      setIsSuccess(true);
    } else {
      setMessage("Please fill in all fields before proceeding.");
      setIsSuccess(false);
    }
  };  

  return (
    <>
      <div id="homeVideo">
        <div className="videoContainer">
          <ul className="videoBG">
            <li className="videoBG"></li>
          </ul>
          <video className="video1-bg" autoPlay loop muted style={{ width: "100%" }}>
            <source src={Mp4Video} />
            <source src={WebmVideo} />
            Video not supported.
          </video>
        </div>
        <div className="fallback_smallscreen"></div>

        <div className="videoContent_blk">
          <div className="widthfix">
            <ul className="videoContent">
              <li className="videoContent">
                <div className="widthfix_corousel">
                  <SliderComponent>
                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Smart Lending:</div>
                      <div className="vc_line2">AI & Blockchain-Powered Banking SaaS Solutions </div>
                      <div className="vc_line3">
                        Advanced automation for Loan Origination, Collections, Dealer Management, Operations, and more
                      </div>
                      <div className="vcButtonBlk">
                        <Link to="/solutions">
                          <button className="vcButton">View More</button>
                        </Link>
                      </div>
                    </div>

                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Streamlined Integrations:</div>
                      <div className="vc_line2">US Mortgage AI & Blockchain Solutions </div>
                      <div className="vc_line3">
                        AI-enhanced Encompass and Calyx integrations to optimize processing time and workflow efficiency
                      </div>
                      <div className="vcButtonBlk">
                        <Link to="/solutions">
                          <button className="vcButton">View More</button>
                        </Link>
                      </div>
                    </div>

                    <div className="widthfix_corousel_content">
                      <div className="vc_line1">Intelligent Assurance:</div>
                      <div className="vc_line2">Comprehensive IT solutions from start to finish</div>
                      <div className="vc_line3">
                        AI-driven underwriting, fraud detection, blockchain-based security, and smart analytics for
                        better decision-making
                      </div>
                      <div className="vcButtonBlk">
                        <Link to="/solutions">
                          <button className="vcButton">View More</button>
                        </Link>
                      </div>
                    </div>
                  </SliderComponent>
                </div>
                <DonatePop isOpen={isOpen2} onClose={togglePopup2} />
              </li>
              <li className="videoContent">
                <div className="joinCommunity">
                  <div className="joinHeadBlk">
                    <h1>Contact Us for Banking Solutions</h1>
                    <h2>Reach us for cutting-edge software solutions, expert guidance & seamless development</h2>
                  </div>
                  <div className="joinFormContainer">
                    <form>
                      <div className="userIcon"></div>
                      <input type="text" id="name" placeholder="Name" value={formData.name} onChange={handleChange} />
                      <ul className="joinFormContainerIIcol">
                        <li className="joinFormContainerIIcol">
                          <div className="phIcon"></div>
                          <input
                            type="text"
                            id="phone"
                            placeholder="Phone"
                            className="no-spinner"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </li>
                        <li className="joinFormContainerIIcol">
                          <div className="mailIcon"></div>
                          <input
                            type="text"
                            id="email"
                            placeholder="Email"
                            className="no-spinner"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </li>
                      </ul>
                      <div className="termsAgree">
                        By clicking on Connect Now you agree to our <Link to="/terms">Terms of Use</Link>
                      </div>
                      <div className="buttonLabel">
                        <input type="button" className="joinButton" value="Connect Now" onClick={handleSubmit} />
                      </div>
                    </form>
                    {message && (
                      <div className={isSuccess ? "successMessage" : "errorMessage"}>
                        {message}
                      </div>
                    )}

                  </div>
                  <div className="socialMedia">
                    <div className="socialMediaIcons">
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="instaIcon"></i>
                      </Link>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="twitterIcon"></i>
                      </Link>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="facebookIcon"></i>
                      </Link>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="youtubeIcon"></i>
                      </Link>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="whatsAppIcon"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
