import { React, Link } from "../../../common";
const Component = () => {
    return (
        <div id="home_bottom_blk">
            <div className="widthfix">
                <ul className="home_bot_ver_col">
                    <li className="home_bot_ver_col">
                        <Link to="/solutions">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon6"></div>
                                </li>
                                <li className="txtBlk">AI-Driven Innovation</li>
                                {/* <li className="txtBlkXoraiShort">Offering Xorai</li> */}
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/solutions">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon1"></div>
                                </li>
                                <li className="txtBlk">Blockchain-Powered Security</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/solutions">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon3"></div>
                                </li>
                                <li className="txtBlk">Efficient Financial Solutions</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/solutions">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon4"></div>
                                </li>
                                <li className="txtBlk">Scalable SaaS Platforms</li>
                            </ul>
                        </Link>
                    </li>
                    <li className="home_bot_ver_col">
                        <Link to="/solutions">
                            <ul className="iconTxtBlk">
                                <li className="iconBlk">
                                    <div className="botIcon5"></div>
                                </li>
                                <li className="txtBlk">Easy Compliance Automation</li>
                            </ul>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Component;