import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';
import { ContactForm } from '../../components';

const Component = () => {



    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Contact Us
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='contact'>
                                <ul className='contactIIcol'>
                                    <li className='contactIIcol'>
                                        <div className="pgHeadText">Get In Touch</div>
                                        <ContactForm />
                                    </li>
                                    <li className='contactIIcol'>
                                        <div className="pgHeadText">Contact Information</div>
                                        <ul className='contactInfo'>
                                            <li className='contactInfo'>
                                                <div className='addressIcon'></div>
                                            </li>
                                            <li className='contactInfo'><b>Moham Uttarana Pvt Ltd</b><br /><br />Np. 740, 15th Cross Rd, KR Layout, JP Nagar Phase 6, J. P. Nagar, Bengaluru, Karnataka 560078.</li>
                                            <br /><br />
                                            <li className='contactInfo'>
                                                <div className='mailIcon'></div>
                                            </li>
                                            <li className='contactInfo'>info@uttarana.com</li>
                                            {/* <li className='contactInfo'>
                                                <div className='phIcon'></div>
                                            </li>
                                            <li className='contactInfo'>99001 23456<br />Everyday: 8AM - 6:30PM</li> */}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;