import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Our Expertise
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Our Expertise</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtNormal'>We specialize in AI-driven and blockchain-powered banking SaaS solutions, optimizing loan origination, collections, and operations with advanced automation. Our secure, cloud-based platforms enhance efficiency, fraud prevention, and risk assessment for the Indian and US financial markets.</div>
                                        <div className='height10'></div>
                                        <div className='pgTxtBoldRed'>AI & Blockchain-Powered Banking SaaS Solutions </div>
                                        <div className='pgTxtNormal'>Advanced automation for Loan Origination, Collections, Dealer Management, Operations, and more</div>
                                        <div className='height10'></div>
                                        <div className='pgTxtBoldRed'>US Mortgage AI & Blockchain Solutions </div>
                                        <div className='pgTxtNormal'>AI-enhanced Encompass and Calyx integrations to optimize processing time and workflow efficiency</div>
                                        <div className='height10'></div>
                                        <div className='pgTxtBoldRed'>End-to-End IT Services </div>
                                        <div className='pgTxtNormal'>AI-driven underwriting, fraud detection, blockchain-based security, and smart analytics for better decision-making</div>
                                        <div className='height15'></div>
                                        <div className='txtBoldBig'>Why Choose Us?</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">Industry-leading AI-driven and blockchain-enabled automation in banking technology</li>
                                            <li className="bp_circleOutline">Secure, tamper-proof solutions for Indian and US financial markets</li>
                                            <li className="bp_circleOutline">AI-powered, cloud-based platforms for efficiency, fraud prevention, and risk assessment</li>
                                            <li className="bp_circleOutline">Blockchain-integrated document management and transaction security</li>
                                        </ul>
                                        <div className='height15'></div>
                                        <div className='pgTxtNormal'><b>Let's innovate together! </b>Contact us today to explore our AI and blockchain-powered solutions.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;