import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        SaaS Solutions
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">SaaS Solutions</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtNormal'>
                                            Our cutting-edge solutions harness AI and Blockchain to revolutionize banking and NBFC operations. From AI-driven Loan Origination Systems to US Mortgage solutions, we deliver seamless, secure, and efficient financial technology. Our custom integrations with Encompass & Calyx empower businesses with automation, accuracy, and compliance-driven innovation.
                                        </div>
                                        <div className='height15'></div>
                                        <div className='txtBoldBig'>AI & Blockchain-Powered Banking & NBFC Solutions</div>
                                        <div className='pgTxtBold'>AI-Driven Loan Origination System (LOS)</div>
                                        <div className='pgTxtNormal'>A cloud-based, AI and blockchain-powered platform that automates the entire loan lifecycle, from application to disbursement. Features include:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline"><b>AI-Based Credit Assessment - </b>Real-time analysis of borrower data for risk profiling.</li>
                                            <li className="bp_circleOutline"><b>Automated Underwriting - </b>AI-driven decision-making to approve or reject applications faster.</li>
                                            <li className="bp_circleOutline"><b>Blockchain-Backed Loan Records - </b>Immutable loan records ensuring data integrity and security.</li>
                                            <li className="bp_circleOutline"><b>Seamless integrations </b>with third-party services like credit bureaus and payment gateways.</li>
                                        </ul>
                                        <div className='height25'></div>
                                        <div className='pgTxtBold'>Secured Collection Management</div>
                                        <div className='pgTxtNormal'>A comprehensive solution for debt collection and recovery, ensuring timely repayments through:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">AI-based predictive analytics for identifying high-risk defaulters.</li>
                                            <li className="bp_circleOutline">Automated payment reminders and legal tracking.</li>
                                            <li className="bp_circleOutline">Blockchain-backed transaction history for transparency and security.</li>
                                            <li className="bp_circleOutline">Smart recovery strategies to optimize repayment rates.</li>
                                        </ul>
                                        <div className='height25'></div>
                                        <div className='pgTxtBold'>Dealer Management System</div>
                                        <div className='pgTxtNormal'>Optimized for automobile and asset financing, this system streamlines dealer commissions, incentive calculations, and loan approvals using:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">AI-based fraud detection for transaction monitoring.</li>
                                            <li className="bp_circleOutline">Automated dealer incentive tracking and payout calculations.</li>
                                            <li className="bp_circleOutline">Blockchain-enabled smart contracts for transparent and tamper-proof dealer payments.</li>
                                        </ul>
                                        <div className='height25'></div>
                                        <div className='pgTxtBold'>Intelligent Operations Management</div>
                                        <div className='pgTxtNormal'>A robust AI and blockchain-driven system designed to improve operational efficiency in banking and NBFC workflows by:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">Automating document verification using AI-based OCR.</li>
                                            <li className="bp_circleOutline">Enhancing compliance tracking through AI-powered anomaly detection.</li>
                                            <li className="bp_circleOutline">Blockchain-powered audit trails ensuring regulatory transparency.</li>
                                        </ul>
                                        <div className='height25'></div>
                                        <div className='pgTxtBold'>Agent Management</div>
                                        <div className='pgTxtNormal'>A smart platform that enables loan agents to track leads, manage commissions, and streamline loan approvals using:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">AI-based lead scoring to prioritize high-conversion opportunities.</li>
                                            <li className="bp_circleOutline">Automated agent performance tracking and incentive management.</li>
                                            <li className="bp_circleOutline">Blockchain-verified commission payouts ensuring transparency.</li>
                                        </ul>
                                        <div className='height25'></div>
                                        <div className='pgTxtBold'>Secured Document Management</div>
                                        <div className='pgTxtNormal'>A secure repository for financial documents with:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">AI-powered document classification and automated indexing.</li>
                                            <li className="bp_circleOutline">Blockchain-backed immutable document storage preventing fraud.</li>
                                            <li className="bp_circleOutline">Smart search capabilities for faster document retrieval.</li>
                                            <li className="bp_circleOutline">Compliance management using AI-driven audit trails.</li>
                                        </ul>
                                        <div className='height25'></div>
                                        <div className='txtBoldBig'>US Mortgage AI & Blockchain Solutions</div>
                                        <div className='pgTxtBold'>AI-Powered Encompass & Calyx Custom Integrations</div>
                                        <div className='pgTxtNormal'>We specialize in developing custom AI-powered add-ons and integrations for Encompass and Calyx, improving processing speed, reducing manual effort, and optimizing mortgage workflows through:</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">AI-driven document processing and data extraction.</li>
                                            <li className="bp_circleOutline">Automated compliance tracking using machine learning.</li>
                                            <li className="bp_circleOutline">Blockchain-backed mortgage record management ensuring tamper-proof transactions.</li>
                                            <li className="bp_circleOutline">Smart loan application assessments using AI-based predictive analytics.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;