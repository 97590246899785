import React from 'react';

const AboutMohamGroup = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtNormal'>At Moham Group, we are a diversified multinational conglomerate with a presence in multiple industries. From technology and finance to automotive, retail, agriculture and re-insurance, we are committed to delivering innovative solutions across the globe. Our vision is to drive positive change and create lasting value in all the markets we serve.</div>

                <ul className='contentIIcol'>
                    <li className='contentIIcol'>
                        <div className='txtBoldBig'>Indian Based Companies</div>

                        <div className='pgTxtBoldRed'>Moham Info Solutions Private Limited</div>
                        <div className='pgTxtNormal'>Moham Info Solutions Private Limited is a leading IT solutions company with over 20 years of expertise in delivering cutting-edge software and consulting services across various industries. We specialize in custom software development, system integration, and enterprise solutions tailored to meet evolving business needs. Our commitment to innovation and technology-driven strategies enables businesses to optimize operations and achieve digital transformation. With a strong focus on quality and customer-centric solutions, we empower organizations to stay ahead in a competitive landscape.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Moham Capital Private Limited (MCPL)</div>
                        <div className='pgTxtNormal'>MCPL is one of the most diversified NBFCs in the country, registered with the RBI and trusted by over 10 lakh satisfied customers. We specialize in investment management, wealth advisory, and structured finance solutions, offering tailored financial strategies for long-term success. With a strong network of branches, we ensure seamless access to our services, combining innovation, reliability, and customer-centric solutions to drive financial growth.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Moham Auto Private Limited</div>
                        <div className='pgTxtNormal'>Moham Auto Private Limited is a prominent player in the automotive industry, specializing in the distribution of high-quality two-wheelers, parts, and related services. Serving both individual customers and large fleets, the company is committed to ensuring customer satisfaction and top-tier performance. <br/>As part of Moham Auto, Siddhi Auto and Mayura Auto are key distributors of Honda and TVS two-wheelers. They focus on advanced vehicle sales and fleet management solutions, delivering reliable products with a strong emphasis on performance.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Moham Retails Private Limited</div>
                        <div className='pgTxtNormal'>Moham Retails Private Limited is a leading name in the retail sector, offering a diverse range of high-quality apparel through its physical stores and e-commerce platforms. Committed to excellence, the company focuses on delivering exceptional products and services that cater to modern consumer needs. With a seamless shopping experience both online and offline, Moham Retails continues to set new standards in the fashion industry.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Moham Re-Insurance Private Limited</div>
                        <div className='pgTxtNormal'>Moham Re-Insurance Private Limited provides comprehensive reinsurance solutions to help businesses effectively manage risk and safeguard their financial future. As a trusted partner in the industry, we offer tailored strategies to ensure stability and resilience. Moham Re has been provisionally approved by the Indian Insurance Regulator (IRDA), making it the second domestic reinsurer in India. Our commitment to excellence and innovation sets us apart in the evolving insurance landscape.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Moham Uttarana Private Limited</div>
                        <div className='pgTxtNormal'>Moham Uttarana Private Limited is a leading industrial solutions provider, leveraging innovative technologies across manufacturing, infrastructure, and engineering sectors. We harness AI and Blockchain to transform banking and NBFC operations with secure and efficient financial technology. Our AI-driven Loan Origination Systems and US Mortgage solutions streamline processes for enhanced accuracy and compliance. We empower businesses with automation-driven innovation.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Istakapaza Private Limited</div>
                        <div className='pgTxtNormal'>Istakapaza specializes in blockchain solutions for the real estate and mortgage sectors. Established in 2018, the company offers a digital ecosystem designed to provide secure and innovative services to transform the real estate industry. Additionally, Istakapaza operates a smart marketplace that connects agri-retailers and Farmer Producer Organizations (FPOs) directly with manufacturers, facilitating the procurement of inputs and the sale of farm outputs. The platform also aims to introduce farm advisory services and precision agriculture tools, such as drone technology, to support users in optimizing their operations. </div>
                    </li>
                    <li className='contentIIcol'>
                        <div className='txtBoldBig'>USA Based Companies</div>

                        <div className='pgTxtBoldRed'>i3Lending Inc</div>
                        <div className='pgTxtNormal'>i3 Lending is a leading mortgage service provider in the USA, offering competitive rates, unique loan programs, and specialty products through a vast network of lenders. With over 15 years of experience and a team of 100+ dedicated loan officers, we have helped more than 10,000 satisfied customers achieve their homeownership dreams. Our commitment to exceptional customer service and strong client relationships ensures a seamless and confident borrowing experience. At i3 Lending, we strive to make the mortgage process simple, transparent, and tailored to every client's needs.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>ESS Solutions LLC</div>
                        <div className='pgTxtNormal'>ESS Solutions LLC is a USA-based global technology consulting firm specializing in software development, cloud computing, and IT consulting. We provide innovative, tailored solutions to help businesses optimize operations, enhance scalability, and drive digital transformation. With a team of experienced professionals, we deliver cutting-edge technology strategies that empower organizations to stay ahead in a rapidly evolving digital landscape. Our expertise ensures businesses leverage the latest advancements for sustained growth and success.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Siddhi Financials LLP</div>
                        <div className='pgTxtNormal'>Siddhi Financials LLP is a trusted mortgage provider in the USA, offering competitive Non-QM mortgage rates tailored to your financial needs. We specialize in helping clients refinance, consolidate debt, or purchase their dream home with ease. Our team of experts is committed to making the mortgage process fast, simple, and stress-free. With Siddhi Financials LLP, you get personalized solutions and dedicated support every step of the way.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Siddhi Venture Capital LLP</div>
                        <div className='pgTxtNormal'>Siddhi Venture Capital LLP is a dynamic venture capital firm dedicated to fueling high-growth startups and emerging businesses with both funding and strategic expertise. With a sector-agnostic approach, the firm partners with innovative entrepreneurs to drive sustainable growth and long-term success. Siddhi VC goes beyond capital investment, offering mentorship, industry insights, and strategic guidance to help businesses scale effectively. Committed to fostering innovation, the firm plays a key role in shaping the future of disruptive and high-potential ventures.</div>
                        <div className='height10'></div>
                        <div className='pgTxtBoldRed'>Sanav Infosolutions LLP</div>
                        <div className='pgTxtNormal'>Sanav Infosolutions LLP is a premier IT consulting firm specializing in data-driven solutions, system integration, and technology consulting for businesses of all sizes. With a focus on innovation and efficiency, the company helps organizations leverage technology to optimize operations and drive growth. Their expertise spans across various industries, delivering tailored solutions that enhance business performance and digital transformation. Sanav Infosolutions LLP is committed to providing cutting-edge technology strategies that empower businesses to stay ahead in a rapidly evolving digital landscape.</div>
                    </li>
                </ul>
                

                

            </div>
            
        </div>
    );
};

export default AboutMohamGroup;