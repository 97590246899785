import React from 'react';
import { Link } from 'react-router-dom';

// import { DonatePop } from '../components';

const RightTop = () => {
    // const [isOpen2, setIsOpen2] = React.useState(false);

    // const togglePopup2 = () => {
    //     setIsOpen2(!isOpen2);
    // };

    return (
        <>
            <div className="topRight_btnLabel">
                {/* <input type='button' className='login_button' onClick={() => window.fnSign(1)} title='SignIn / SignUp' /> */}
               <Link to="/contact"> <input type="button" className="donate_button" value="Reach Us" 
                // onClick={togglePopup2} 
                /></Link>
            </div>
            {/* <DonatePop isOpen={isOpen2} onClose={togglePopup2} /> */}
        </>
    );
};

export default RightTop;