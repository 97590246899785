import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "../screens/home";
import Expertise from "../screens/headertabs/expertise";
import Solutions from "../screens/headertabs/solutions";
import Privacy from "../screens/privacy";
import Terms from "../screens/terms";
import Contact from "../screens/headertabs/contact";
import About from "../screens/headertabs/about";
import SuccessStories from "../screens/headertabs/success";
import Career from "../screens/headertabs/career";

const RouteLinks = [
  { id: 1, path: "", Component: Home },
  { id: 2, path: "expertise", Component: Expertise },
  { id: 3, path: "solutions", Component: Solutions },
  { id: 4, path: "privacy", Component: Privacy },
  { id: 5, path: "terms", Component: Terms },
  { id: 6, path: "contact", Component: Contact },
  { id: 7, path: "about", Component: About },
  { id: 8, path: "success", Component: SuccessStories },
  { id: 9, path: "career", Component: Career },
];

function PrivateRoute({ children }) {
  let loggedin = sessionStorage.getItem("user");
  return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = () => {
  return (
    <Routes>
      {RouteLinks.map((x) => {
        if (x.Session) {
          return (
            <Route
              key={x.id}
              path={"/" + x.path}
              element={
                <PrivateRoute>
                  <x.Component />
                </PrivateRoute>
              }
            />
          );
        } else {
          return <Route key={x.id} path={"/" + x.path} element={<x.Component />} />;
        }
      })}
    </Routes>
  );
};

export default RendorRoutes;
//   return (
//     <Routes>
//       {RouteLinks.map((x) => {
//         const Component = x.Component;
//         if (x.Session) {
//           return (
//             <Route
//               key={x.id}
//               path={"/" + x.path}
//               element={
//                 <PrivateRoute>
//                   <Component />
//                 </PrivateRoute>
//               }
//             />
//           );
//         } else {
//           return <Route key={x.id} path={"/" + x.path} element={<Component />} />;
//         }
//       })}
//     </Routes>
//   );
// };

// export default RendorRoutes;