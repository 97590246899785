import React from 'react';
import { Link } from 'react-router-dom';

// Expertise
const mnu1 = [
    "/expertise"
];
// Solutions
const mnu2 = [
    "/solutions"
];
// Success Stories
const mnu3 = [
    "/success"
];

// Success Stories
const mnu4 = [
    "/about"
];

// More
const mnu5 = [
    "/contact", "/privacy", "/terms", "/career"
];

const Navbar = (props) => {
    const { loc } = props;

    const IsActive = (mnu) => {
        return mnu.findIndex((x) => x === loc) > -1 ? "dropbtn active" : "dropbtn";
    };

    return (

        <div>
             <div className="dropdown">
                <Link to="/solutions"><button className={IsActive(mnu2)}>
                    SaaS Solutions
                    {/* <div className="arrow_down_topnav"></div> */}
                </button></Link>
            </div>
            <div className="dropdown">
                <Link to="/expertise"><button className={IsActive(mnu1)}>
                    Our Expertise
                </button></Link>
            </div>
            <div className="dropdown">
                <Link to="/success"><button className={IsActive(mnu3)}>
                    Success Stories
                </button></Link>
            </div>
            <div className="dropdown">
                <Link to="/about"><button className={IsActive(mnu4)}>
                    About Us
                </button></Link>
            </div>
            <div className="dropdown">
                <Link to="#"><button className={IsActive(mnu5)}>
                    More <div className="line_topnav"></div>
                </button></Link>
                <div className="dropdown-content">
                    <Link to="/contact">Contact Us</Link>
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link to="/terms">Terms Of Use</Link>
                    <Link to="/career">Work With Us</Link>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
