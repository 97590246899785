import React from 'react';

const ManagementTeam = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtBold'>Management Team</div>
            </div>

        </div>
    );
};

export default ManagementTeam;
