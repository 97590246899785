import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Success Stories
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Success Stories</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtNormal'>
                                        Empowering financial institutions with cutting-edge AI & Blockchain-powered banking solutions, we have transformed lending with our AI-driven Loan Origination System (LOS). Our expertise in US Mortgage AI & Blockchain solutions has streamlined processes, while our AI-powered Encompass & Calyx custom integrations have enhanced efficiency and compliance. Driving innovation, one success at a time!
                                        </div>
                                        <div className='txtBoldBig'>AI & Blockchain-Powered Loan Origination Success for an NBFC</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">Implemented our AI and blockchain-driven LOS, reducing loan processing time by 40%.</li>
                                            <li className="bp_circleOutline">Automated underwriting and credit scoring using AI for faster approvals.</li>
                                            <li className="bp_circleOutline">Integrated with CIBIL for real-time credit analysis and fraud detection.</li>
                                            <li className="bp_circleOutline">Blockchain-backed loan records ensured tamper-proof compliance.</li>
                                        </ul>
                                        <div className='height15'></div>
                                        <div className='txtBoldBig'>Enhancing Collection Efficiency with AI & Blockchain-Driven Recovery</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">Integrated AI-based predictive analytics to identify high-risk borrowers.</li>
                                            <li className="bp_circleOutline">Automated debt recovery strategies increased collection rates by 30%.</li>
                                            <li className="bp_circleOutline">AI-driven chatbots assisted borrowers with repayment options in real time.</li>
                                            <li className="bp_circleOutline">Blockchain-backed payment tracking improved transparency.</li>
                                        </ul>
                                        <div className='height15'></div>
                                        <div className='txtBoldBig'>Optimizing Encompass Workflows with AI & Blockchain for a US Mortgage Firm</div>
                                        <ul className="bulletPoints_un">
                                            <li className="bp_circleOutline">AI-powered data extraction reduced processing time by 50%.</li>
                                            <li className="bp_circleOutline">Automated compliance tracking ensured regulatory adherence.</li>
                                            <li className="bp_circleOutline">AI-based fraud detection helped flag high-risk mortgage applications.</li>
                                            <li className="bp_circleOutline">Blockchain-enabled mortgage data ensured security and immutability.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;