import React from 'react';

const AboutUttarana = () => {
    return (
        <div className='padBot20'>
            <div className='aboutUs'>
                <div className='pgTxtNormal'>Moham Uttarana Pvt Ltd is the IT division of Moham Group, a multinational conglomerate with operations in banking, retail, agriculture, automobile, real estate, IT, and other industries across the USA and India.</div>
                <div className='pgTxtNormal'>As the technology powerhouse of the group, we provide advanced IT solutions that streamline operations and drive digital transformation in the financial sector. Our AI and blockchain-powered SaaS platforms are designed to address the unique needs of banks, NBFCs, and mortgage firms.</div>
                <div className='height10'></div>
                <div className='txtBoldBig'>Our Mission</div>
                <div className='pgTxtNormal'>Our mission at Moham Uttarana is to revolutionize financial technology by developing intelligent, secure, and AI & blockchain-driven solutions. We strive to empower banks, NBFCs, and mortgage firms with cutting-edge SaaS platforms that enhance operational efficiency and customer experience. By leveraging advanced technologies, we streamline financial processes, improve decision-making, and drive digital transformation. Our commitment to innovation ensures that financial institutions stay ahead in a rapidly evolving digital landscape. With a focus on security, scalability, and automation, we aim to redefine how financial services are delivered. Through our expertise, we enable seamless, future-ready solutions that shape the future of banking and finance.</div>
                <div className='height10'></div>
                <div className='txtBoldBig'>Our Vision</div>
                <div className='pgTxtNormal'>Our mission at Moham Uttarana is to lead the financial technology revolution by delivering AI and blockchain-powered banking IT solutions that set new industry standards. We empower banks, NBFCs, and mortgage firms with intelligent, secure, and scalable platforms that enhance efficiency, compliance, and digital transformation. By leveraging advanced automation and data-driven insights, we streamline financial operations and drive innovation. Our commitment to security, adaptability, and cutting-edge technology ensures that financial institutions stay ahead in an evolving digital landscape. Through continuous innovation, we aim to redefine the future of banking and financial services.</div>
            </div>
        </div>
    );
};

export default AboutUttarana;
