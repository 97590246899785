import { Link } from "react-router-dom";
import { Developer } from "../components";

const FooterFull = () => {
    return (
        <div id="footer_home">
            <div className="widthfix">
                <ul className="footerVerCol">
                    <li className="footerVerCol">
                        <div className="sscsbLogo footerLogoSetting"></div>
                        <div className="footerCopyright">Moham Uttarana</div>
                        <div className="footerCopyright">&copy; {new Date().getFullYear()} | All rights reserved.</div>
                        <div className="footerTerms"><Link to="/terms" className="termsLink">Terms of Use</Link>&nbsp; | &nbsp;<Link to="/privacy" className="termsLink">Privacy Policy</Link></div>
                    </li>
                    <li className="footerVerCol">
                        <div className="footerHeadText">Moham Uttarana</div>
                        {/* <div style={{ color: "#ececec", marginBottom: "10px", fontSize: "large" }}>Naamghar Address</div> */}
                        <div className="footerContact">1st Cross St, Maruthi Nagar, <br />Lakshminarayana Pura,  <br />Chennappa Layout, <br />Chinnapanna Halli, Bengaluru, <br />Karnataka 560037 <br /><br />Everyday: 8AM - 6:30PM</div>
                        {/* <div style={{ color: "#ececec", marginBottom: "10px", fontSize: "large" }}>Registered Address</div>
                        <div className="footerContact">No 16, 2nd Main, Abbaiah Reddy Layout,
                            Kaggadaspura, Bengaluru, Karnataka 560093</div> */}
                        {/* <ul className="footerContTxtIconBlk">
                            <li className="footerContTxtIconBlk"><div className="fotPhIcon"></div></li>
                            <li className="footerContTxtIconBlk">+91 990-010-6821</li>
                            <li className="footerContTxtIconBlk"><div className="fotMailIcon"></div></li>
                            <li className="footerContTxtIconBlk">sscsbangalore@gmail.com</li>
                        </ul> */}
                    </li>
                    <li className="footerVerCol">
                        <div className="footerHeadText">Registered Address</div>
                        <div className="footerContact">No 16, 2nd Main, <br />Abbaiah Reddy Layout,
                            <br />Kaggadaspura, Bengaluru, <br />Karnataka 560093</div>
                        <ul className="footerContTxtIconBlk">
                            <li className="footerContTxtIconBlk"><div className="fotPhIcon"></div></li>
                            <li className="footerContTxtIconBlk">+91 990-010-6821</li>
                            <li className="footerContTxtIconBlk"><div className="fotMailIcon"></div></li>
                            <li className="footerContTxtIconBlk">sscsbangalore@gmail.com</li>
                        </ul>
                        {/* <ul className="footerQuickLink">
                            <li className="footerQuickLink"><Link to="/about" className="quickLink">About Bangalore Namghar</Link></li>
                            <li className="footerQuickLink"><Link to="/galleryphotoevents" className="quickLink">Photo &amp; Video Gallery</Link></li>
                            <li className="footerQuickLink"><Link to="/xorai" className="quickLink">Offer Xorai</Link></li>
                            <li className="footerQuickLink"><Link to="/eventcalendar" className="quickLink">Events Calendar</Link></li>
                            <li className="footerQuickLink"><Link to="/contact" className="quickLink">Contact Us</Link></li>
                        </ul> */}
                    </li>
                    <li className="footerVerCol">
                        <div className="footerHeadText">Get Updates</div>
                        <div className="footerGetUpdate">We'll keep you posted on fortcoming events, vibrant festivals and beyond</div>
                        <div className="fotterGetUpdateFormBlk">
                            <input type="email" placeholder="Your E-mail" />
                            <Link to="#"><div className="footerBtnBlk"></div></Link>
                        </div>
                        <Developer />
                    </li>
                </ul>
            </div>

        </div>
    );
}

const FooterSmall = () => {
    return (
        <div id="footer">
            <div className="footer_blk">
                <div className="widthfix">
                    <div className="footerInpage">
                    Moham Uttarana Pvt Ltd &copy; {new Date().getFullYear()} | All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
}

const Component = ({ type }) => {
    return type ? <FooterFull /> : <FooterSmall />
};

export default Component;