import { React, Header } from "../../common";
import { HomeVideo, HomeViewBottom } from "./childs";

const Component = () => {

  return (
    <>
      <Header type={"PreLogin"} />

      {/* Home Video */}
      <HomeVideo />

      {/* Homepage Bottom */}
      <HomeViewBottom />
    </>
  );
};

export default Component;
