import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from "../../container";
import AboutTabs from "./component/AboutTabs";
import AboutTabContent from "./component/AboutTabContent";

const AboutUs = () => {
    const [activeTab, setActiveTab] = useState('About Uttarana');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 799);

    const inPageTabs = [
        { name: 'About Uttarana', label: 'About Uttarana' },
        { name: 'About Moham Group', label: 'About Moham Group' },
        // { name: 'Management Team', label: 'Management Team' },
    ];

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 799);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container headerType={'inner'}>
            <div id="container">
                <div id="content_fullHeader">
                    <div className='inPgHeadBlueBg'>
                        <div className="widthfix">
                            <ul className='breadcrumbLink'>
                                <li className="breadcrumbLink">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="breadcrumbLink">
                                    {activeTab}
                                </li>
                            </ul>
                            {isMobile ? (
                                <div className="dropdown-container">
                                    <select value={activeTab} onChange={(e) => handleTabChange(e.target.value)}>
                                        {inPageTabs.map((tab) => (
                                            <option key={tab.name} value={tab.name}>{tab.label}</option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <div className="tabs-container">
                                    <AboutTabs inPageTabs={inPageTabs} activeTab={activeTab} onTabChange={handleTabChange} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="content-container">
                        <div className="widthfix">
                            <AboutTabContent activeTab={activeTab} />
                        </div>
                    </div>

                    <div className="widthfix"></div>
                </div>
            </div>
        </Container>
    );
};

export default AboutUs;
