import React, { useState } from 'react';

const ContactForm = () => {
    // State for form data
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
    });

    // State for message and validation
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent page reload

        const { name, email, phone, subject, message } = formData;

        if (name.trim() && email.trim() && phone.trim() && subject.trim() && message.trim()) {
            setMessage("Your message has been sent successfully!");
            setIsSuccess(true);
        } else {
            setMessage("Please fill in all fields before submitting.");
            setIsSuccess(false);
        }
    };

    return (
        <>
            <div className="contactFormContainer">
                <form onSubmit={handleSubmit}>
                    <ul className='contactFormIIcol'>
                        <li>
                            <label htmlFor="name">Name:</label>
                            <div className="userIcon"></div>
                            <input
                                type="text"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </li>
                        <li>
                            <label htmlFor="email">Email:</label>
                            <div className="mailIcon"></div>
                            <input
                                type="text"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </li>
                        <li>
                            <label htmlFor="phone">Phone:</label>
                            <div className="phIcon"></div>
                            <input
                                type="text"
                                id="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </li>
                        <li>
                            <label htmlFor="subject">Subject:</label>
                            <div className="subjectIcon"></div>
                            <input
                                type="text"
                                id="subject"
                                value={formData.subject}
                                onChange={handleChange}
                            />
                        </li>
                        <li className='singleRow'>
                            <label htmlFor="message">Message:</label>
                            <div className="msgIcon"></div>
                            <textarea
                                id="message"
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                        </li>
                        <div className='buttonLabel'>
                            <input type="submit" value="Submit" className='submitButton'/>
                        </div>
                    </ul>
                </form>

                {/* Show success or error message */}
                {message && (
                    <div className={isSuccess ? "successMessage" : "errorMessage"}>
                        {message}
                    </div>
                )}
            </div>
        </>
    );
};

export default ContactForm;
