import React from 'react';
import Container from "../../container";
import { Link } from 'react-router-dom';

const Component = () => {
    return (
        <>
            <Container headerType={'inner'}>
                <div id="container" >
                    <div id="content_fullHeader">
                        <div className='inPgHeadBlueBg'>
                            <div className="widthfix">
                                <ul className='breadcrumbLink'>
                                    <li className="breadcrumbLink">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumbLink">
                                        Work With Us
                                    </li>
                                </ul>
                                <div className='padBot60'></div>
                            </div>
                        </div>
                        <div className="widthfix">
                            <div className='events'>
                                <div className='eventIcol'>
                                    <div className="pgHeadText">Work With Us</div>
                                    <div className='contentPad20'>
                                        <div className='pgTxtNormal'>
                                        Explore flexible, remote-friendly roles in banking technology and help us transform financial services by making everyday work simpler, more secure, and more efficient.
                                        </div>
                                        <div className='txtBoldBig'>GenAI Specialist</div>
                                        <ul class="careerPoints">
                                            <li>⁠2 to 4 years of experience as Data Scientist or GenAI specialist</li>
                                            <li>2 to 3 years of experience in Generative AI solution development.</li>
                                            <li>⁠Proven track record and experience with with GenAI technologies</li>
                                            <li>⁠Open source LLMs like Llama, Gemma, Mixtral etco Closed source LLMs such as Open AI GPT, Azure Open AI, Claude, Gemini etco Prompt Engineering/Tuning, RAG, RAFT, LLM finetuning such as PEFT(LoRA, QLoRA ..)</li>
                                            <li>⁠Understanding of SLMs such as Phi3, BERT and Transformer architectureo Vector databases like Pincone, Qdrant etc.</li>
                                            <li>⁠Good knowledge of advanced statistical methods. Experience working with Text Data using transformer-based model</li>
                                            <li>⁠Expertise with the following scripting languages:o Python, R, Tensorflow, Keras, Pytorch</li>
                                            <li>⁠OpenNLP, CoreNLP, WordNet, NLTK, SpaCy, Gensim, Large Language Models, Knowledge Graphs</li>
                                            <li>⁠Good and experience of machine learning algorithms and ability to apply them in supervised and un-supervised NLP tasks.</li>
                                            <li>Knowledge of NLP algorithms that can handle various NLP tasks such as intent recognition, entity extraction, language modeling, text classification, question answering, text summarization, topic modeling and so on.</li>
                                            <li>Experience building and fine-tuning Language Models (LMs), such as BERT, ELMo, XLNet etc to solve bespoke NLP tasks.</li>
                                            <li>Good to have foundational knowledge on Cloud, API frameworks like Flask, Fast API, Swagger/Postman tools</li>
                                        </ul>  
                                        <div className='height15'></div>

                                        <div className='pgTxtBold'>Mandatory Skills</div>
                                        <ul class="careerPoints">
                                            <li>Design, develop, test, and deploy Machine Learning models using state-of-the-art algorithms with a strong focus on language models.</li>
                                            <li>⁠Strong understanding of LLMs, and associated technologies like RAG, Agents, VectorDB and Guardrails</li>
                                            <li>⁠Hand-on experience in GenAI frameworks like LlamaIndex, Langchain, Autogen, etc.</li>
                                            <li>⁠Experience in cloud services like Azure, GCP and AWS</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Component;